const rentalImages = {
  badWeatherIconSource: require('../../../../assets/images/products/renters/bad-weather-icon.png'),
  bikeIconSource: require('../../../../assets/images/products/renters/bike-icon.png'),
  cameraIconSource: require('../../../../assets/images/products/renters/camera-icon.png'),
  diamondRingIconSource: require('../../../../assets/images/products/renters/diamond-ring-icon.png'),
  fireDamageIconSource: require('../../../../assets/images/products/renters/fire-damage-icon.png'),
  friendsIconSource: require('../../../../assets/images/products/renters/friends-icon.png'),
  guitarIconSource: require('../../../../assets/images/products/renters/guitar-icon.png'),
  heartIconSource: require('../../../../assets/images/products/renters/heart-icon.png'),
  hotelIconSource: require('../../../../assets/images/products/renters/hotel-icon.png'),
  laptopIconSource: require('../../../../assets/images/products/renters/laptop-icon.png'),
  smartphoneIconSource: require('../../../../assets/images/products/renters/smartphone-icon.png'),
  startDateIconSource: require('../../../../assets/images/products/renters/start-date.png'),
  theftIconSource: require('../../../../assets/images/products/renters/theft-icon.png'),
  waterDamageIconSource: require('../../../../assets/images/products/renters/water-damage-icon.png'),
  rentersLiabilityIconSource: require('../../../../assets/images/products/renters/renters-liability.png'),
};

export default rentalImages;
