import AsyncState from '../QuoteScreen/utils/AsyncState';

export default interface RentalQuoteScreenLocalState extends AsyncState {
  deductible: number;
  hasAdditionalInsured: boolean;
  hasPersonalProperty: boolean;
  hasSpouse: boolean;
  lossOfUse: number;
  maximumAmountReimbursed: number;
  personalLiability: number;
  quotePrice: number;
  startDate: string;
  isLoading: boolean;
}

export const rentalScreenInitialState = {
  deductible: 0,
  hasAdditionalInsured: false,
  hasPersonalProperty: false,
  hasSpouse: false,
  lossOfUse: 0,
  maximumAmountReimbursed: 0,
  personalLiability: 0,
  quotePrice: 0,
  startDate: '',
  isLoading: false,
};
