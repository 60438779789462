import React from 'react';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import QuoteScreenSectionContainer from '../QuoteScreen/QuoteScreenSectionContainer';
import { ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';

type WhatIsNotCoveredModel = {
  label: string;
  description: string;
  imageSource: ImageSourcePropType;
};

const Container = styled.View`
  width: 332px;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
`;

const FirstGroupWhatIsNotCoveredItems: WhatIsNotCoveredModel[] = [
  {
    label: 'War & Nuclear Hazard',
    description:
      'It seems obvious but it’s worth pointing out. Your policy will not cover you if the loss is caused by, results from or arises out war or warlike operation or nuclear hazard. If it were, insurance companies would go broke VERY quickly.',
    imageSource: require('../../../assets/images/products/renters/war-nuclear-icon.png'),
  },
  {
    label: 'Illegal Stuff',
    description:
      'If you’re the one illegally and/or intentionally causing harm or damaging property, your coverage won’t apply.',
    imageSource: require('../../../assets/images/products/renters/illegal-stuff-icon.png'),
  },
  {
    label: 'Earthquake',
    description:
      'Unfortunately, tectonic plates are stronger than insurance at the moment. If your loss is caused by an earthquake or earth movement, it won’t be covered',
    imageSource: require('../../../assets/images/products/renters/earthquake-icon.png'),
  },
];

const LastWhatIsNotCoveredItem = {
  label: 'Flood',
  description:
    'Any loss caused by flood or external water is excluded from the coverage',
  imageSource: require('../../../assets/images/products/renters/flood-icon.png'),
};

export default function WhatIsNotCovered() {
  return (
    <QuoteScreenSectionContainer
      backgroundColor="gray"
      title="What's Not Covered...">
      {FirstGroupWhatIsNotCoveredItems.map((model) => (
        <Container key={model.label}>
          <CoverageTitleIcon source={model.imageSource} />
          <Spacer x={2} y={2} />
          <WaffleText
            style={{
              fontSize: 20,
              fontWeight: '500',
              textAlign: 'center',
            }}>
            {model.label}
          </WaffleText>
          <Spacer x={2} y={2} />
          <CoverageCardDescription>{model.description}</CoverageCardDescription>
          <Spacer x={2} y={4} />
        </Container>
      ))}
      <Container key={LastWhatIsNotCoveredItem.label}>
        <CoverageTitleIcon source={LastWhatIsNotCoveredItem.imageSource} />
        <Spacer x={2} y={2} />
        <WaffleText
          style={{
            fontSize: 20,
            fontWeight: '500',
            textAlign: 'center',
          }}>
          {LastWhatIsNotCoveredItem.label}
        </WaffleText>
        <Spacer x={2} y={2} />
        <CoverageCardDescription>
          {LastWhatIsNotCoveredItem.description}
        </CoverageCardDescription>
      </Container>
    </QuoteScreenSectionContainer>
  );
}
