import CoverageCard from '../../Components/CoverageCard';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import rentalImages from './utils/rental-images';
import { PlainView, Spacer } from '../../Components/SimpleComponents';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import React from 'react';
import { Horizontal, VerticalCenter } from '../../Components/SimpleLayouts';
import { Platform } from 'react-native';
import DateInput from '../../Components/DateInput';
import add from 'date-fns/add';
import WaffleText from '../../Components/WaffleText';

type Props = {
  startDate: string | undefined;
  onChangeStartDate: (dte: string) => Promise<void>;
};

export function StartDateCompact({ startDate, onChangeStartDate }: Props) {
  return (
    <>
      <VerticalCenter style={{}}>
        <Horizontal
          style={{
            alignItems: 'flex-start',
            width: 335,
            height: Platform.OS === 'ios' ? 110 : undefined,
          }}>
          <WaffleText
            style={{
              width: 110,
              fontSize: 18,
              marginTop: 15,
              fontWeight: '500',
              color: '#737373',
              textAlign: 'right',
            }}>
            Start Date
          </WaffleText>

          <Spacer x={1.5} />

          <PlainView style={{ width: 147 }}>
            <DateInput
              emptyDisplayValue={'Today'}
              value={startDate}
              placeholder={'Start date   YYYY-MM-DD'}
              initialDate={new Date()}
              minimumDate={new Date()}
              maximumDate={add(new Date(), { days: 30 })}
              onChangeText={(dte: string) => onChangeStartDate(dte)}
              containerStyle={{ width: 147 }}
              inputContainerStyle={{
                backgroundColor: 'white',
                borderColor: '#88847E',
              }}
            />
          </PlainView>
        </Horizontal>

        <WaffleText
          style={{
            fontSize: 11,
            fontWeight: '400',
            color: '#373737',
          }}>
          Specify a start date up to 30 days in the future.
        </WaffleText>
      </VerticalCenter>
    </>
  );
}

export function StartDate({ startDate, onChangeStartDate }: Props) {
  const width = 290;

  return (
    <CoverageCard bgColor="gray">
      <CoverageTitleIcon source={rentalImages.startDateIconSource} />
      <Spacer x={2} y={2} />
      <CoverageCardTitle>Change Start Date</CoverageCardTitle>
      <Spacer x={2} y={2} />
      <CoverageCardDescription>
        Specify a start date up to 30 days in the future. If blank, defaults to
        today.
      </CoverageCardDescription>
      <Spacer x={2} y={4} />
      <VerticalCenter
        style={{
          width,
          height: Platform.OS === 'ios' ? 110 : undefined,
        }}>
        <DateInput
          value={startDate}
          placeholder={'Start date   YYYY-MM-DD'}
          initialDate={new Date()}
          minimumDate={new Date()}
          maximumDate={add(new Date(), { days: 30 })}
          onChangeText={(dte: string) => onChangeStartDate(dte)}
          containerStyle={{ width }}
          inputContainerStyle={{
            backgroundColor: 'white',
            borderColor: '#88847E',
          }}
        />
      </VerticalCenter>
    </CoverageCard>
  );
}
