import React from 'react';
import CoverageCard from '../../Components/CoverageCard';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import rentalImages from './utils/rental-images';
import Toggle from '../../Components/Toggle';

type Props = {
  isEnabled: boolean;
  onAdditionalInsuredToggle: () => Promise<void>;
};

export default function AdditionalInsured({
  isEnabled,
  onAdditionalInsuredToggle,
}: Props) {
  return (
    <CoverageCard bgColor="gray">
      <CoverageTitleIcon source={rentalImages.friendsIconSource} />
      <Spacer x={2} y={2} />
      <CoverageCardTitle>Additional Insured</CoverageCardTitle>
      <Spacer x={2} y={2} />
      <CoverageCardDescription>
        You can add another person who lives with you on this policy. Just add
        their name below.
      </CoverageCardDescription>
      <Spacer x={2} y={4} />
      <Toggle isEnabled={isEnabled} onPress={onAdditionalInsuredToggle} />
    </CoverageCard>
  );
}
