import React, { useLayoutEffect } from 'react';
import { WebView } from 'react-native-webview';
import { Logger } from '../Helper/Logger';
import { Loading } from '../Components/Loading';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../screen-config';
import WaffleText, { WaffleTextFamily } from '../Components/WaffleText';
import { View } from 'react-native';

const defaultOnMessage = (e) => {
  Logger(`Received message: ${JSON.stringify(e?.nativeEvent?.data)}`);
};

const Header = ({ title }: { title: string }) => (
  <View
    style={{
      maxWidth: 350,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    }}>
    <WaffleText
      style={{
        fontFamily: WaffleTextFamily,
        fontSize: 20,
        fontWeight: '400',
        lineHeight: 26,
        textAlign: 'center',
        width: 270,
        flexWrap: 'wrap',
      }}>
      {title}
    </WaffleText>
  </View>
);

const SimpleWebScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'Simple Web'>) => {
  const uri = route?.params.uri ?? 'https://www.google.com/';
  const title = route?.params.title ?? ' ';
  const loadingText = route?.params.loadingText ?? null;
  const headers = route?.params.headers ?? {};
  const method = route?.params.method ?? 'GET';
  const onMessage = route?.params.onMessage ?? defaultOnMessage;

  // Logger(`DEBUG: loadingText="${loadingText}" headers=${JSON.stringify(headers)} method=${method}`);

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      // headerRight: route?.params.headerRight ?? null,
      headerRight: route?.params.headerRight ?? null,
      headerLeft: route?.params.headerLeft ?? null,
      headerTitle: () => <Header title={title} />,
    });
  }, [navigation]);

  return (
    <WebView
      source={{ uri, headers, method }}
      startInLoadingState={true}
      onMessage={onMessage}
      renderLoading={() => <Loading loadingText={loadingText} />}
    />
  );
};

export default SimpleWebScreen;
