import React from 'react';
import WaffleText from '../../Components/WaffleText';
import styled from 'styled-components/native';
import { ImageSourcePropType } from 'react-native';
import rentalImages from './utils/rental-images';

const hasAllIconSources =
  rentalImages.theftIconSource &&
  rentalImages.fireDamageIconSource &&
  rentalImages.badWeatherIconSource &&
  rentalImages.waterDamageIconSource;

const Container = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const PropertyIcon = styled.Image`
  width: 50px;
  height: 50px;
`;

const LabeledIconContainer = styled.View`
  width: 50px;
  justify-content: flex-start;
  flex-direction: column;
`;

type LabeledIconProps = {
  source: ImageSourcePropType;
  label: string;
};
const LabeledIcon = function LabeledIcon({ source, label }: LabeledIconProps) {
  return (
    <LabeledIconContainer>
      <PropertyIcon source={source} />
      <WaffleText style={{ fontSize: 10, textAlign: 'center' }}>
        {label}
      </WaffleText>
    </LabeledIconContainer>
  );
};

export default function PersonalPropertyCoveredAgainstIcons() {
  if (!hasAllIconSources) return <WaffleText>img</WaffleText>;

  return (
    <Container>
      <LabeledIcon
        source={rentalImages.theftIconSource}
        label="Theft Crime Vandalism"
      />
      <LabeledIcon
        source={rentalImages.fireDamageIconSource}
        label="Fire Damage"
      />
      <LabeledIcon
        source={rentalImages.badWeatherIconSource}
        label="Hail Windstorm Bad Weather"
      />
      <LabeledIcon
        source={rentalImages.waterDamageIconSource}
        label="Water Damage"
      />
    </Container>
  );
}
