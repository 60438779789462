import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import RentersQuoteScreenView from './RentersQuoteScreenView';
import { RootStackParamList } from '../../../screen-config';
import { StackScreenProps } from '@react-navigation/stack';
import { Logger } from '../../Helper/Logger';
import { fetchDraftPoliciesAsync } from '../../Components/DesignPolicy/SuggestedCoverageShared';
import {
  GetDraftUserPolicies,
  GetDraftUserPolicies_draftUserPolicies,
  GetDraftUserPolicies_draftUserPolicies_coverage,
  LocalUser,
  RepriceDraftPolicies,
  UpdatePolicyAnswerAndReprice,
  UpdatePolicyAnswerAndRepriceVariables,
} from '../../../../operation-result-types';
import {
  DRAFT_POLICIES,
  GET_LOCAL_USER,
  REPRICE_DRAFT_POLICIES,
  UPDATE_POLICY_ANSWERS_AND_REPRICE,
} from '../../GraphQL/Waffle/Queries';
import { mapRenterPolicyToLocalState } from './utils/rental-policy-to-state-mapper';
import RentalQuoteScreenLocalState, {
  rentalScreenInitialState,
} from './RentalQuoteScreenLocalState';
import updateCoverageValueAndSetStateWithMapperAsync from '../QuoteScreen/utils/update-coverage-and-map-to-state';
import { decrementValue, incrementValue } from '../../Helper/ValueFunctions';
import openFullPolicy from '../QuoteScreen/utils/open-full-policy';
import { useAuthContext } from '../AuthProvider';
import goToNextPolicyOrCheckoutAsync from '../QuoteScreen/utils/checkout-policy';
import {
  PRODUCT_TYPE_OPTION_LIABILITY_AND_CONTENTS,
  PRODUCT_TYPE_OPTION_LIABILITY_ONLY,
} from './utils/rental-coverage.constants';
import { getRentalCoverages } from './utils/get-rental-coverages';
import { findRentalPolicy } from './utils/find-rental-policy';

const setAppIsLoading = (
  draftRentersPolicy: GetDraftUserPolicies_draftUserPolicies
) => {
  return function setComposedState(
    prevState: RentalQuoteScreenLocalState
  ): RentalQuoteScreenLocalState {
    const mappedState = mapRenterPolicyToLocalState(
      draftRentersPolicy,
      prevState
    );
    return {
      ...mappedState,
      isLoading: true,
    };
  };
};

const setAppIsNotLoading = (
  draftRentersPolicy: GetDraftUserPolicies_draftUserPolicies
) => {
  return function setComposedState(
    prevState: RentalQuoteScreenLocalState
  ): RentalQuoteScreenLocalState {
    const mappedState = mapRenterPolicyToLocalState(
      draftRentersPolicy,
      prevState
    );
    return {
      ...mappedState,
      isLoading: false,
    };
  };
};

export default function RentersQuoteScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Renters Quote'>): React.ReactElement {
  const client = useApolloClient();
  const { isGuest } = useAuthContext();
  const [reprice] = useMutation<RepriceDraftPolicies>(REPRICE_DRAFT_POLICIES);
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);

  const { data, loading: draftPolicyIsLoading } =
    useQuery<GetDraftUserPolicies>(DRAFT_POLICIES, {
      fetchPolicy: 'network-only',
    });

  const [localState, setLocalState] = useState<RentalQuoteScreenLocalState>(
    rentalScreenInitialState
  );

  const draftRentersPolicy = findRentalPolicy(data?.draftUserPolicies);

  const {
    productCoverage,
    liabilityCoverage,
    contentCoverageLimit,
    contentCoverageDeductible,
  } = getRentalCoverages(draftRentersPolicy?.coverage);

  const refetchDraftPolicyAsync = async () => {
    // @ts-ignore
    const newDraftPolicies = await fetchDraftPoliciesAsync(client);

    return findRentalPolicy(newDraftPolicies?.data?.draftUserPolicies);
  };

  const rePriceAndFetchDraftPoliciesAsync = async () => {
    Logger(`RentersQuoteScreen: start initial pricing`);
    setLocalState(setAppIsLoading(draftRentersPolicy));

    // TODO: use the messages?
    await reprice();

    const updatedRentalPolicy = await refetchDraftPolicyAsync();
    setLocalState(setAppIsNotLoading(updatedRentalPolicy));

    Logger(`RentersQuoteScreen: done pricing tab`);
  };

  useEffect(() => {
    if (draftPolicyIsLoading) return;

    if (!draftRentersPolicy || draftRentersPolicy?.price === 0) {
      rePriceAndFetchDraftPoliciesAsync().then();
    } else if (draftRentersPolicy?.price !== localState.quotePrice) {
      setLocalState(setAppIsNotLoading(draftRentersPolicy));
    }
  }, [draftPolicyIsLoading]);

  const updatePersonalPropertyCoverageValueAsync = async (
    coverage: GetDraftUserPolicies_draftUserPolicies_coverage,
    currentValue
  ) => {
    const newValue = currentValue
      ? PRODUCT_TYPE_OPTION_LIABILITY_ONLY
      : PRODUCT_TYPE_OPTION_LIABILITY_AND_CONTENTS;

    return updateCoverageValueAndSetStateWithMapperAsync<RentalQuoteScreenLocalState>(
      {
        client,
        coverageId: coverage.id,
        coverageLimit: newValue,
        setState: setLocalState,
        mapDraftPolicyToState: mapRenterPolicyToLocalState,
        findPolicy: findRentalPolicy,
      }
    );
  };

  const incrementCoverageValueAsync = async (
    coverage: GetDraftUserPolicies_draftUserPolicies_coverage,
    currentValue: number
  ) => {
    const newValue = incrementValue(currentValue.toString(), coverage.coverage);

    return updateCoverageValueAndSetStateWithMapperAsync<RentalQuoteScreenLocalState>(
      {
        client,
        coverageId: coverage.id,
        coverageLimit: newValue,
        setState: setLocalState,
        mapDraftPolicyToState: mapRenterPolicyToLocalState,
        findPolicy: findRentalPolicy,
      }
    );
  };

  const decrementCoverageValueAsync = async (
    coverage: GetDraftUserPolicies_draftUserPolicies_coverage,
    currentValue: number
  ) => {
    const newValue = decrementValue(currentValue.toString(), coverage.coverage);

    return updateCoverageValueAndSetStateWithMapperAsync<RentalQuoteScreenLocalState>(
      {
        client,
        coverageId: coverage.id,
        coverageLimit: newValue,
        setState: setLocalState,
        mapDraftPolicyToState: mapRenterPolicyToLocalState,
        findPolicy: findRentalPolicy,
      }
    );
  };

  const updateStartDate = async (startDate: string) => {
    if (draftRentersPolicy.answerInputs?.__typename === 'PolicyRentersAnswer') {
      setLocalState(setAppIsLoading(draftRentersPolicy));

      const answer_inputs = JSON.stringify({
        ...draftRentersPolicy.answerInputs,
        StartDate: startDate,
      });

      await client.mutate<
        UpdatePolicyAnswerAndReprice,
        UpdatePolicyAnswerAndRepriceVariables
      >({
        mutation: UPDATE_POLICY_ANSWERS_AND_REPRICE,
        variables: {
          input: {
            id: draftRentersPolicy.id,
            answer_inputs,
          },
        },
      });

      const updatedRentalPolicy = await refetchDraftPolicyAsync();
      setLocalState(setAppIsNotLoading(updatedRentalPolicy));
    }
  };

  return (
    <RentersQuoteScreenView
      navigation={navigation}
      deductible={localState.deductible}
      hasAdditionalInsured={localState.hasAdditionalInsured}
      hasPersonalProperty={localState.hasPersonalProperty}
      hasSpouse={localState.hasSpouse}
      isLoading={localState.isLoading}
      lossOfUse={localState.maximumAmountReimbursed * 0.2}
      maximumAmountReimbursed={localState.maximumAmountReimbursed}
      startDate={localState.startDate}
      onAddSpouseToggle={() => {
        setLocalState((prevState) => ({
          ...prevState,
          hasSpouse: !localState.hasSpouse,
        }));
        return Promise.resolve();
      }}
      onAdditionalInsuredToggle={() => {
        setLocalState((prevState) => ({
          ...prevState,
          hasAdditionalInsured: !localState.hasAdditionalInsured,
        }));
        return Promise.resolve();
      }}
      onDeductibleDecrease={() =>
        decrementCoverageValueAsync(
          contentCoverageDeductible,
          localState.deductible
        )
      }
      onDeductibleIncrease={() =>
        incrementCoverageValueAsync(
          contentCoverageDeductible,
          localState.deductible
        )
      }
      onMaximumAmountReimbursedDecrease={() =>
        decrementCoverageValueAsync(
          contentCoverageLimit,
          localState.maximumAmountReimbursed
        )
      }
      onMaximumAmountReimbursedIncrease={() =>
        incrementCoverageValueAsync(
          contentCoverageLimit,
          localState.maximumAmountReimbursed
        )
      }
      onOpenFullPolicy={() =>
        openFullPolicy(user.state, draftRentersPolicy.productId)
      }
      onPersonalLiabilityDecrease={() =>
        decrementCoverageValueAsync(
          liabilityCoverage,
          localState.personalLiability
        )
      }
      onPersonalLiabilityIncrease={() =>
        incrementCoverageValueAsync(
          liabilityCoverage,
          localState.personalLiability
        )
      }
      onPersonalPropertyToggle={() =>
        updatePersonalPropertyCoverageValueAsync(
          productCoverage,
          localState.hasPersonalProperty
        )
      }
      onChangeStartDate={async (startDate) => await updateStartDate(startDate)}
      onGoToNextPolicyOrCheckout={() =>
        goToNextPolicyOrCheckoutAsync({
          client,
          isGuest,
          user,
          navigation,
          policyProductIds: data?.draftUserPolicies.map((p) => p.productId),
        })
      }
      personalLiability={localState.personalLiability}
      quotePrice={localState.quotePrice}
    />
  );
}
