import { useQuery, useApolloClient } from '@apollo/client';
import React, { useLayoutEffect } from 'react';
import {
  NavigationGoSettingsButton,
  Spacer,
} from '../Components/SimpleComponents';
import { VerticalPacked, VerticalSpread } from '../Components/SimpleLayouts';
import { SettingsButton } from '../Components/Settings/SettingsButton';
import { InvoicesButton } from '../Components/Settings/InvoicesButton';
import { ContentUnified } from '../Components/ContentShared';
import { StackScreenProps } from '@react-navigation/stack';
import {
  BoostAdditionalInterestScreenName,
  ChangePasswordScreenName,
  ManagePlanScreenName,
  RootStackParamList,
  WebScreenName,
} from '../../screen-config';
import { useAuthContext } from './AuthProvider';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';
import { BlueHeaderImage } from '../Components/HeaderNavTitle.web';
import { WaffleTextFamily } from '../Components/WaffleText';
import { Platform } from 'react-native';
import CustomLinking from '../Helper/CustomLinking';
import { CognitoSignOut } from '../Helper/CognitoAuth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GetFinalizedUserPolicies } from '../../../operation-result-types';
import { USER_POLICIES } from '../GraphQL/Waffle/Queries';
import { HasRentersPolicy } from '../Helper/PolicyHelper';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';
import { ClearUserState } from '../Helper/UserHelpers';
import { cachedTokenReactiveVar } from '../GraphQL/Waffle/ApolloClientBuilder';

const SettingsScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Settings'>) => {
  const client = useApolloClient();
  const { setIsSignedIn } = useAuthContext();
  const { data } = useQuery<GetFinalizedUserPolicies>(USER_POLICIES, {
    fetchPolicy: 'cache-and-network',
  });
  const userTracking = useUserTracking();
  const policies = data?.finalizedUserPolicies ?? [];
  const hasRenters = HasRentersPolicy(policies);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Settings',
      headerBackTitleVisible: false,
      headerTitleStyle: {
        fontFamily: WaffleTextFamily,
        fontSize: 24,
        fontWeight: '400',
        lineHeight: 30,
        width: 250,
        textAlign: 'center',
      },
      headerShown: isNativeOrMobileWeb(),
    });
  }, [navigation]);

  async function signOutAsync() {
    await CognitoSignOut();

    setIsSignedIn(null);
    cachedTokenReactiveVar('');
    await AsyncStorage.clear();
    await client.resetStore();
    await userTracking.reset();
  }

  // style={styles.fullHeightMinusHeaderFooter}

  const onOpenExternalPage = (isPrivacyPolicy) => {
    const uri = isPrivacyPolicy
      ? 'https://www.trywaffle.com/privacy-policy'
      : 'https://www.trywaffle.com/terms-of-use';
    const title = isPrivacyPolicy ? 'Privacy Policy' : 'Terms of Use';
    if (Platform.OS === 'web') {
      CustomLinking.openURL(uri, '_blank');
    } else {
      navigation.navigate(WebScreenName, {
        uri,
        title,
        headerRight: NavigationGoSettingsButton,
      });
    }
  };

  return (
    <ContentUnified title={'Account'} image={BlueHeaderImage}>
      <VerticalSpread>
        <VerticalPacked>
          <SettingsButton
            name={`Manage Plan`}
            style={{ backgroundColor: '#D1E9FF' }} // value details
            textStyle={{ color: '#000000' }}
            onPress={() => navigation.navigate(ManagePlanScreenName)}
          />
        </VerticalPacked>

        <Spacer y={1} />

        <InvoicesButton
          label={'Payment Method'}
          warning={
            'The Payment Method for Pet and Cyber can only be managed through our ' +
            "Partners' portals. If there are any issues, please contact us through " +
            'the app’s chat function.'
          }
          always={true}
        />

        <Spacer y={1} />

        <InvoicesButton />

        <Spacer y={5} />

        {isNativeOrMobileWeb() && (
          <>
            <VerticalPacked>
              <SettingsButton
                name={'Terms & Conditions'}
                onPress={() => onOpenExternalPage(false)}
              />

              <Spacer y={1} />

              <SettingsButton
                name={'Privacy Policy'}
                onPress={() => onOpenExternalPage(true)}
              />
            </VerticalPacked>

            <Spacer y={5} />
          </>
        )}

        <>
          <VerticalPacked>
            <SettingsButton
              name={`Add Renter's Additional Interest`}
              disabled={!hasRenters}
              style={{ backgroundColor: '#D1E9FF' }} // value details
              textStyle={{ color: '#000000' }}
              onPress={() =>
                navigation.navigate(BoostAdditionalInterestScreenName)
              }
            />
          </VerticalPacked>

          <Spacer y={5} />
        </>

        <VerticalPacked>
          <SettingsButton
            name={'Change Password'}
            style={{ backgroundColor: '#D1E9FF' }} // value details
            textStyle={{ color: '#000000' }}
            onPress={() => navigation.navigate(ChangePasswordScreenName)}
          />

          <Spacer y={1} />

          <SettingsButton
            name={'Logout'}
            style={{ backgroundColor: '#4297e7' }} // waffle blue
            onPress={async () => await signOutAsync()}
          />

          <Spacer y={1} />
        </VerticalPacked>
      </VerticalSpread>
    </ContentUnified>
  );
};

export default SettingsScreen;
