export const LIABILITY_COVERAGE_LIMIT = 'Liability Coverage Limit';
export const PRODUCT_TYPE = 'Product Type';
export const CONTENT_COVERAGE_LIMITS = 'Content Coverage Limits';
export const CONTENT_COVERAGE_DEDUCTIBLE = 'Content Coverage Deductible';
export const PRODUCT_TYPE_OPTION_LIABILITY_ONLY = 'Liability Only';
export const PRODUCT_TYPE_OPTION_LIABILITY_AND_CONTENTS =
  'Liability and Contents';

export type RentalProductCoverageId =
  | 'Liability Coverage Limit'
  | 'Product Type'
  | 'Content Coverage Limits'
  | 'Content Coverage Deductible'
  | 'Liability and Contents';
