import { GetDraftUserPolicies_draftUserPolicies } from '../../../../../operation-result-types';
import RentalQuoteScreenLocalState, {
  rentalScreenInitialState,
} from '../RentalQuoteScreenLocalState';
import {
  CONTENT_COVERAGE_DEDUCTIBLE,
  CONTENT_COVERAGE_LIMITS,
  LIABILITY_COVERAGE_LIMIT,
  PRODUCT_TYPE,
  PRODUCT_TYPE_OPTION_LIABILITY_ONLY,
} from './rental-coverage.constants';
import { getRentalCoverageValue } from './get-rental-coverages';

export const mapRenterPolicyToLocalState = (
  draftRentersPolicy: GetDraftUserPolicies_draftUserPolicies,
  prevState: RentalQuoteScreenLocalState = rentalScreenInitialState
): RentalQuoteScreenLocalState => {
  if (!draftRentersPolicy?.coverage) return prevState;

  const hasPersonalProperty =
    getRentalCoverageValue(draftRentersPolicy.coverage, PRODUCT_TYPE) !==
    PRODUCT_TYPE_OPTION_LIABILITY_ONLY;

  const maximumReimbursedRaw = getRentalCoverageValue(
    draftRentersPolicy.coverage,
    CONTENT_COVERAGE_LIMITS
  );
  const maximumAmountReimbursed =
    parseInt(maximumReimbursedRaw) || prevState.maximumAmountReimbursed;

  const personalLiabilityRaw = getRentalCoverageValue(
    draftRentersPolicy.coverage,
    LIABILITY_COVERAGE_LIMIT
  );
  const personalLiability =
    parseInt(personalLiabilityRaw) || prevState.personalLiability;

  const deductibleRaw = getRentalCoverageValue(
    draftRentersPolicy.coverage,
    CONTENT_COVERAGE_DEDUCTIBLE
  );
  const deductible = parseInt(deductibleRaw) || prevState.deductible;

  const quotePrice = draftRentersPolicy.price || prevState.quotePrice;

  const startDate =
    draftRentersPolicy.answerInputs?.__typename === 'PolicyRentersAnswer'
      ? draftRentersPolicy.answerInputs.StartDate
      : prevState.startDate;

  return {
    ...prevState,
    hasPersonalProperty,
    maximumAmountReimbursed,
    personalLiability,
    deductible,
    quotePrice,
    startDate,
  };
};
