import { Animated, useWindowDimensions, View, StyleSheet } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { QuoteScreenScrollView } from '../QuoteScreen/QuoteScreenScrollView';
import { QuoteScreenPriceButton } from '../QuoteScreen/QuotePriceButton';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { Spacer } from '../../Components/SimpleComponents';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import InsuranceTitle from '../QuoteScreen/InsuranceTitle';
import PriceButton from '../../Components/PriceButton';
import { POLICY_TYPE_MONTHLY } from '../../Helper/PolicyHelper';
import { Logger } from '../../Helper/Logger';
import WaffleText, {
  MediumText,
  PopupTitle,
} from '../../Components/WaffleText';
import QuoteScreenSectionContainer from '../QuoteScreen/QuoteScreenSectionContainer';
import { PayOrQuestions } from '../CyberQuoteScreen/PayOrQuestions';
import PersonalProperty from './PersonalProperty';
import LossOfUse from '../RentersQuoteScreen/LossOfUse';
import PersonalLiability from '../RentersQuoteScreen/PersonalLiability';
import AdditionalInsured from '../RentersQuoteScreen/AdditionalInsured';
import AddSpouse from '../RentersQuoteScreen/AddSpouse';
import WhatIsNotCovered from '../RentersQuoteScreen/WhatIsNotCovered';
import { isFullWeb, isNativeOrMobileWeb } from '../../Helper/DeviceHelper';
import PleaseNote from '../QuoteScreen/PleaseNote';
import styled from 'styled-components/native';
import { WaffleLightGrey } from '../../Constants/Style';
import Popup from '../../Components/Popup';
import { useWaffleNavigationHeader } from '../../Components/NavHeader';
import PoweredBy from '../QuoteScreen/PoweredBy';
import { FlexWrapSpacer } from '../QuoteScreen/FlexWrapSpacer';
import { StartDate, StartDateCompact } from './StartDate';
import { FLAG_NAMES, useFlagBooleanValue } from '../../../lib/feature-flags';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});

// TODO: this is a bit of hack for layout
const BackgroundGreySection = styled(VerticalCenter)`
  width: 100%;
  padding-bottom: 45px;
  background-color: ${WaffleLightGrey};
`;

interface RentersDraftPolicyViewModel {
  navigation: StackNavigationProp<RootStackParamList, 'Renters Quote'>;
  deductible: number;
  hasAdditionalInsured: boolean;
  hasPersonalProperty: boolean;
  hasSpouse: boolean;
  isLoading: boolean;
  lossOfUse: number;
  maximumAmountReimbursed: number;
  startDate: string;
  onDeductibleDecrease: () => Promise<void>;
  onDeductibleIncrease: () => Promise<void>;
  onMaximumAmountReimbursedDecrease: () => Promise<void>;
  onMaximumAmountReimbursedIncrease: () => Promise<void>;
  onOpenFullPolicy: () => Promise<void>;
  onPersonalLiabilityDecrease: () => Promise<void>;
  onPersonalLiabilityIncrease: () => Promise<void>;
  onPersonalPropertyToggle: () => Promise<void>;
  onAddSpouseToggle: () => Promise<void>;
  onAdditionalInsuredToggle: () => Promise<void>;
  onChangeStartDate: (dte: string) => Promise<void>;
  onGoToNextPolicyOrCheckout: () => Promise<void>;
  personalLiability: number;
  quotePrice: number;
}

export default function RentersQuoteScreenView({
  navigation,
  ...draftPolicy
}: RentersDraftPolicyViewModel): React.ReactElement {
  const { height: windowHeight } = useWindowDimensions();
  const currentContainerViewScrollY = useRef(new Animated.Value(0)).current;
  const priceButtonStartingY = useRef(5000);
  const ref = useRef<View>(null);
  const [modal, setModel] = useState<{ isOpen: boolean; title: string }>({
    isOpen: false,
    title: 'Great',
  });

  // const enable33PercentOff = useFlagBooleanValue(
  //   FLAG_NAMES.renters_33_percent_off,
  //   false
  // );
  const enable33PercentOff = false;
  // const userTracking = useUserTracking();

  // quote price is the result of a 33% discount
  const basePrice = (draftPolicy.quotePrice / 0.67).toFixed(2);

  useWaffleNavigationHeader(navigation);

  // useEffect(() => {
  //   userTracking.publishExperimentViewed({
  //     variationName: enable33PercentOff ? 'v3.2' : 'v1.0',
  //     variationId: enable33PercentOff ? 'v3.2' : 'v1.0',
  //     experimentName: FLAG_NAMES.renters_33_percent_off,
  //     experimentId: FLAG_NAMES.renters_33_percent_off,
  //   });
  // }, []);

  return (
    <View style={styles.container}>
      <QuoteScreenScrollView
        currentContainerViewScrollY={currentContainerViewScrollY}>
        <QuoteScreenPriceButton
          onPress={draftPolicy.onGoToNextPolicyOrCheckout}
          isUpdating={draftPolicy.isLoading}
          currentContainerViewScrollY={currentContainerViewScrollY}
          startingY={priceButtonStartingY.current}
          windowHeight={windowHeight}
          price={draftPolicy.quotePrice}
        />
        <Popup
          hasSideMenu={false}
          visible={modal.isOpen}
          onDismiss={() => setModel({ ...modal, isOpen: false })}>
          <PopupTitle>{modal.title}!</PopupTitle>
          <WaffleText
            style={{ maxWidth: 225, fontSize: 18, textAlign: 'center' }}>
            {'You can enter their name on the next screen.'}
          </WaffleText>
        </Popup>

        <Spacer y={5} />
        <InsuranceTitle>Ready for Renters Insurance?</InsuranceTitle>
        <Spacer y={1} />

        <PriceButton
          size="large"
          onPress={draftPolicy.onGoToNextPolicyOrCheckout}
          isUpdating={draftPolicy.isLoading}
          basePrice={`${basePrice}`}
          percentOff={33}
          price={draftPolicy.quotePrice}
          policyFrequency={POLICY_TYPE_MONTHLY}
          isExperiment={enable33PercentOff}
        />

        <Spacer y={1.5} />

        <PoweredBy
          width={132}
          height={40}
          source={require('../../../assets/images/products/renters/powered_by.png')}
        />

        {isNativeOrMobileWeb() && (
          <>
            <Spacer y={3.5} />

            <StartDateCompact
              startDate={draftPolicy.startDate}
              onChangeStartDate={draftPolicy.onChangeStartDate}
            />
          </>
        )}

        <Spacer y={6} />

        <View
          ref={(r) => (ref.current = r)}
          onLayout={() => {
            if (ref.current) {
              ref.current.measure((x, y, width, height, pageX, pageY) => {
                priceButtonStartingY.current = y;
                Logger(
                  `Measure: x=${x} y=${y} width=${width} height=${height} pageX=${pageX} pageY=${pageY}`
                );
              });
            }
          }}>
          <Spacer y={5} />
        </View>

        <Spacer y={2.5} />

        <MediumText
          style={{ fontWeight: '400', color: '#919191', textAlign: 'center' }}>
          No hidden fees. Cancel anytime.
        </MediumText>
        <Spacer y={0.5} />
        <MediumText
          style={{ fontWeight: '400', color: '#919191', textAlign: 'center' }}>
          {
            '*Rate credit applicable only to policyholders meeting certain criteria'
          }
        </MediumText>

        <Spacer y={5} />

        <QuoteScreenSectionContainer
          backgroundColor={isFullWeb() ? 'gray' : 'white'}
          title="Your Coverage">
          <PersonalLiability
            value={draftPolicy.personalLiability}
            onIncrease={draftPolicy.onPersonalLiabilityIncrease}
            onDecrease={draftPolicy.onPersonalLiabilityDecrease}
          />
          <FlexWrapSpacer />
          <PersonalProperty
            deductible={draftPolicy.deductible}
            onDeductibleIncrease={draftPolicy.onDeductibleIncrease}
            onDeductibleDecrease={draftPolicy.onDeductibleDecrease}
            onPersonalPropertyToggle={draftPolicy.onPersonalPropertyToggle}
            hasPersonalProperty={draftPolicy.hasPersonalProperty}
            isExperiment={enable33PercentOff}
            isLoading={draftPolicy.isLoading}
            maximumAmountReimbursed={draftPolicy.maximumAmountReimbursed}
            onMaximumAmountReimbursedIncrease={
              draftPolicy.onMaximumAmountReimbursedIncrease
            }
            onMaximumAmountReimbursedDecrease={
              draftPolicy.onMaximumAmountReimbursedDecrease
            }
          />
          {draftPolicy.hasPersonalProperty ? (
            <>
              <FlexWrapSpacer />
              <View
                style={{
                  flexDirection: 'column',
                }}>
                <LossOfUse value={draftPolicy.lossOfUse} />
              </View>
            </>
          ) : null}
        </QuoteScreenSectionContainer>

        <QuoteScreenSectionContainer
          backgroundColor="white"
          title="Useful Extras">
          {isFullWeb() && (
            <>
              <StartDate
                startDate={draftPolicy.startDate}
                onChangeStartDate={draftPolicy.onChangeStartDate}
              />
              <FlexWrapSpacer />
            </>
          )}
          <AddSpouse
            isEnabled={draftPolicy.hasSpouse}
            onAddSpouseToggle={() => {
              if (!draftPolicy.hasSpouse && !modal.isOpen) {
                setModel({ title: 'Great', isOpen: true });
              }
              return draftPolicy.onAddSpouseToggle();
            }}
          />
          <FlexWrapSpacer />
          <AdditionalInsured
            isEnabled={draftPolicy.hasAdditionalInsured}
            onAdditionalInsuredToggle={() => {
              if (!draftPolicy.hasAdditionalInsured && !modal.isOpen) {
                setModel({ title: 'Awesome', isOpen: true });
              }
              return draftPolicy.onAdditionalInsuredToggle();
            }}
          />
        </QuoteScreenSectionContainer>

        <WhatIsNotCovered />

        <BackgroundGreySection>
          <PleaseNote onOpenFullPolicy={draftPolicy.onOpenFullPolicy} />
        </BackgroundGreySection>

        <PayOrQuestions />
        <Spacer y={20} />
      </QuoteScreenScrollView>
    </View>
  );
}
