import React from 'react';
import CoverageCard from '../../Components/CoverageCard';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import rentalImages from './utils/rental-images';
import Toggle from '../../Components/Toggle';

type Props = {
  isEnabled: boolean;
  onAddSpouseToggle: () => Promise<void>;
};

export default function AddSpouse({ isEnabled, onAddSpouseToggle }: Props) {
  const content =
    'Your spouse is automatically covered under this policy! You’re welcome :-D. Add their name below.';

  return (
    <CoverageCard bgColor="gray">
      <CoverageTitleIcon source={rentalImages.heartIconSource} />
      <Spacer x={2} y={2} />
      <CoverageCardTitle>Add Spouse</CoverageCardTitle>
      <Spacer x={2} y={2} />
      <CoverageCardDescription>{content}</CoverageCardDescription>
      <Spacer x={2} y={4} />
      <Toggle isEnabled={isEnabled} onPress={onAddSpouseToggle} />
    </CoverageCard>
  );
}
