import { GetDraftUserPolicies_draftUserPolicies_coverage } from '../../../../../operation-result-types';
import {
  CONTENT_COVERAGE_LIMITS,
  PRODUCT_TYPE,
  RentalProductCoverageId,
  CONTENT_COVERAGE_DEDUCTIBLE,
  LIABILITY_COVERAGE_LIMIT,
} from './rental-coverage.constants';

function findRentalCoverage(
  coverages: GetDraftUserPolicies_draftUserPolicies_coverage[],
  productCoverageId: RentalProductCoverageId
) {
  return coverages.find((c) => c.productCoverageId === productCoverageId);
}

export function getRentalCoverageValue(
  coverages: GetDraftUserPolicies_draftUserPolicies_coverage[],
  productCoverageId: RentalProductCoverageId
) {
  return findRentalCoverage(coverages, productCoverageId)?.coverageLimit;
}

type RentalCoverage = {
  productCoverage: GetDraftUserPolicies_draftUserPolicies_coverage;
  liabilityCoverage: GetDraftUserPolicies_draftUserPolicies_coverage;
  contentCoverageLimit: GetDraftUserPolicies_draftUserPolicies_coverage;
  contentCoverageDeductible: GetDraftUserPolicies_draftUserPolicies_coverage;
};

export function getRentalCoverages(
  coverages: GetDraftUserPolicies_draftUserPolicies_coverage[] = []
): RentalCoverage {
  const productCoverage = findRentalCoverage(coverages, PRODUCT_TYPE);

  const liabilityCoverage = findRentalCoverage(
    coverages,
    LIABILITY_COVERAGE_LIMIT
  );

  const contentCoverageDeductible = findRentalCoverage(
    coverages,
    CONTENT_COVERAGE_DEDUCTIBLE
  );

  const contentCoverageLimit = findRentalCoverage(
    coverages,
    CONTENT_COVERAGE_LIMITS
  );

  return {
    productCoverage,
    liabilityCoverage,
    contentCoverageLimit,
    contentCoverageDeductible,
  };
}
