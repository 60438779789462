import React from 'react';
import CoverageCard from '../../Components/CoverageCard';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import CoverageCardCurrencyValue from '../../Components/CoverageCard/CoverageCardMonetaryValue';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import rentalImages from './utils/rental-images';

type Props = {
  value: number;
};

export default function LossOfUse({ value }: Props) {
  return (
    <CoverageCard bgColor="white">
      <CoverageTitleIcon source={rentalImages.hotelIconSource} />
      <Spacer x={2} y={2} />
      <CoverageCardTitle>Loss of Use</CoverageCardTitle>
      <Spacer x={2} y={2} />
      <CoverageCardDescription>
        That’s when your rental property becomes uninhabitable. Loss of use
        covers the hotel, airbnb or rental home as well as your living expenses.
        This coverage is always set to 20% of the amount of Personal Property
        coverage you selected above.
      </CoverageCardDescription>
      <Spacer x={2} y={4} />
      <CoverageCardCurrencyValue value={value} />
    </CoverageCard>
  );
}
