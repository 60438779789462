import React from 'react';
import CoverageCard from '../../Components/CoverageCard';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageCardCounter from '../../Components/CoverageCard/CoverageCardCounter';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import rentalImages from './utils/rental-images';

type Props = {
  onIncrease: () => Promise<void>;
  onDecrease: () => Promise<void>;
  value: number;
};

export default function PersonalLiability({
  value,
  onIncrease,
  onDecrease,
}: Props) {
  const content =
    "This may cover another person's medical bills or repairs to their property if you're found legally responsible for causing the harm. There are a few restrictions to this coverage. Make sure you check it out here.";
  return (
    <CoverageCard bgColor="white">
      <CoverageTitleIcon source={rentalImages.rentersLiabilityIconSource} />
      <Spacer x={2} y={2} />
      <CoverageCardTitle>Personal Liability</CoverageCardTitle>
      <Spacer x={2} y={2} />
      <CoverageCardDescription>{content}</CoverageCardDescription>
      <Spacer x={2} y={4} />
      <CoverageCardCounter
        value={value}
        onIncrease={onIncrease}
        onDecrease={onDecrease}
      />
    </CoverageCard>
  );
}
