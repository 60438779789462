import React from 'react';
import CoverageCard from '../../../Components/CoverageCard';
import { Spacer } from '../../../Components/SimpleComponents';
import { VerticalCenter } from '../../../Components/SimpleLayouts';
import WaffleText from '../../../Components/WaffleText';
import { WaffleGrey } from '../../../Constants/Style';
import CoverageCardCounter from '../../../Components/CoverageCard/CoverageCardCounter';
import CoverageCardDescription from '../../../Components/CoverageCard/CoverageCardDescription';
import CoverageCardTitle from '../../../Components/CoverageCard/CoverageCardTitle';
import PersonalPropertyIcons from '../PersonalPropertyIcons';
import PersonalPropertyCoveredAgainstIcons from '../PersonalPropertyCoveredAgainstIcons';
import Toggle from '../../../Components/Toggle';

const title = 'Personal Property';
const description = `That’s the stuff you own, including your laptop, bike, jewelry and much more. It’s covered whether your stuff is damaged or stolen - at home or outside your home (as long as you’re in the U.S. .... Don’t make us go to Canada!)`;

interface PersonalPropertyViewModel {
  deductible: number;
  isLoading: boolean;
  onDeductibleIncrease: () => Promise<void>;
  onDeductibleDecrease: () => Promise<void>;
  onPersonalPropertyToggle: () => Promise<void>;
  hasPersonalProperty: boolean;
  maximumAmountReimbursed: number;
  onMaximumAmountReimbursedIncrease: () => Promise<void>;
  onMaximumAmountReimbursedDecrease: () => Promise<void>;
}

export default function PersonalProperty(
  personalProperty: PersonalPropertyViewModel
): React.ReactElement {
  return (
    <CoverageCard bgColor="white">
      <VerticalCenter>
        <PersonalPropertyIcons />
        <Spacer x={2.5} y={2.5} />
        <CoverageCardTitle>{title}</CoverageCardTitle>

        <Spacer y={1.5} />

        <CoverageCardDescription>{description}</CoverageCardDescription>

        <Spacer x={2} y={4} />

        <Toggle
          isDisabled={personalProperty.isLoading}
          onPress={personalProperty.onPersonalPropertyToggle}
          isEnabled={personalProperty.hasPersonalProperty}
        />

        {!personalProperty.hasPersonalProperty ? null : (
          <>
            <Spacer x={4} y={4} />
            <CoverageCardCounter
              label="Maximum Amount Reimbursed"
              value={personalProperty.maximumAmountReimbursed}
              onIncrease={personalProperty.onMaximumAmountReimbursedIncrease}
              onDecrease={personalProperty.onMaximumAmountReimbursedDecrease}
            />

            <Spacer x={4} y={4} />

            <CoverageCardCounter
              label="Deductible"
              value={personalProperty.deductible}
              onIncrease={personalProperty.onDeductibleIncrease}
              onDecrease={personalProperty.onDeductibleDecrease}
              description="Note: All wind/hail events have a $1,000 deductible except in certain
        states."
            />
            <Spacer x={2} y={2} />

            <CoverageCardTitle>
              Yes, your stuff is covered against
            </CoverageCardTitle>

            <Spacer x={2} y={2} />

            <PersonalPropertyCoveredAgainstIcons />

            <Spacer x={2} y={2} />
            <WaffleText
              style={{
                fontSize: 13,
                fontStyle: 'normal',
                fontWeight: 'normal',
                textAlign: 'center',
                color: WaffleGrey,
              }}>
              There are some reimbursement limits for certain items and a few
              exclusions. You can see the full list of items here.
            </WaffleText>
          </>
        )}
      </VerticalCenter>
    </CoverageCard>
  );
}
