import React, { useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import PasswordInput from '../Components/PasswordInput';
import { VerticalCenter } from '../Components/SimpleLayouts';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import AuthenticationService from '../Helper/AuthenticationService';
import { CenterLinkText, Spacer } from '../Components/SimpleComponents';
import { MediumText } from '../Components/WaffleText';
import { ErrorDisplay } from '../Components/ErrorDisplay';
import WaffleTextInput from '../Components/WaffleTextInput';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import style, { WaffleOrange } from '../Constants/Style';
import { StackScreenProps } from '@react-navigation/stack';
import {
  LoginScreenName,
  RootStackParamList,
  WebScreenName,
} from '../../screen-config';

const schema = yup.object().shape({
  email: yup.string().required().email(),
  code: yup.string().min(5).required(),
  password: yup.string().min(8).required(),
});

interface State {
  email?: string;
  code?: string;
  password?: string;
  confirmPassword?: string;
}

const isValid = (state: State) =>
  schema.isValidSync(state) && state.password === state.confirmPassword;

const noMatchMessage = (state: State) => {
  const { password, confirmPassword } = state;

  if (
    password?.length >= 8 &&
    confirmPassword?.length >= 8 &&
    password !== confirmPassword
  ) {
    return 'Passwords do not match';
  } else {
    return null;
  }
};

const ResetPasswordScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'Reset Password'>) => {
  const email = route.params?.email ?? '';
  const [state, setState] = useState<State>({ email });
  const [error, setError] = useState('');
  const containerStyle = { width: 300 };
  const disabled = !isValid(state);

  const onChangeText = (key: keyof State, text: string) =>
    setState({ ...state, [key]: text });

  const doResetAsync = async () => {
    try {
      await AuthenticationService.ResetPassword(
        state.email,
        state.code,
        state.password
      );

      await navigation.navigate(LoginScreenName, {
        initialEmail: state.email,
        initialMessage: 'Password changed.  Please login',
      });
    } catch (e) {
      setError(e);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
    });
  }, [navigation]);

  return (
    <KeyboardViewMaybe>
      <WaffleCreateHeader title={'Reset Password'} />

      <VerticalCenter style={[style.fullWidth, style.fullHeight]}>
        <ErrorDisplay error={error} />

        <Spacer y={1} />

        <VerticalCenter style={{ marginHorizontal: 45 }}>
          <MediumText>
            We have sent a password reset code by email to {email}. Enter it
            below to reset your password.
          </MediumText>
        </VerticalCenter>

        <Spacer y={1} />

        <WaffleTextInput
          label={'Code'}
          placeholder={'Confirmation Code'}
          otherProps={{
            autoCapitalize: 'none',
            autoCorrect: false,
            keyboardType: 'number-pad',
          }}
          containerStyle={containerStyle}
          onChangeText={(text: string) => onChangeText('code', text)}
        />

        <Spacer y={1} />

        <PasswordInput
          label={'New Password'}
          onChangeText={(text: string) => onChangeText('password', text)}
          otherProps={{
            textContentType: 'newPassword',
          }}
          containerStyle={containerStyle}
          footerText={'8 or more characters'}
        />
        <Spacer y={1} />

        <PasswordInput
          label={'Confirm New Password'}
          onChangeText={(text: string) => onChangeText('confirmPassword', text)}
          otherProps={{
            textContentType: 'newPassword',
          }}
          containerStyle={containerStyle}
          errorFooterText={noMatchMessage(state)}
        />

        <Spacer y={2} />

        <WaffleButtonAsync
          disabled={disabled}
          onPress={doResetAsync}
          name={'Change Password'}
          style={{ backgroundColor: disabled ? '#E0E0E0' : WaffleOrange }}
        />

        <Spacer y={2} />

        <CenterLinkText onPress={() => navigation.navigate(WebScreenName)}>
          Go back
        </CenterLinkText>
      </VerticalCenter>
    </KeyboardViewMaybe>
  );
};

export default ResetPasswordScreen;
