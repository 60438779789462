import React from 'react';
import CoverageCard from '../../../Components/CoverageCard';
import { Spacer } from '../../../Components/SimpleComponents';
import { VerticalCenter } from '../../../Components/SimpleLayouts';
import WaffleText from '../../../Components/WaffleText';
import { WaffleOrange } from '../../../Constants/Style';
import CoverageCardCounter from '../../../Components/CoverageCard/CoverageCardCounter';
import CoverageCardDescription from '../../../Components/CoverageCard/CoverageCardDescription';
import CoverageCardTitle from '../../../Components/CoverageCard/CoverageCardTitle';
import PersonalPropertyIcons from '../PersonalPropertyIcons';
import Toggle from '../../../Components/Toggle';
import styled from 'styled-components/native';
import rentalImages from '../utils/rental-images';

const title = 'Personal Property';
const description = `That’s the stuff you own, including your laptop, bike, jewelry and much more. It’s covered whether your stuff is damaged or stolen - at home or outside your home (as long as you’re in the U.S. .... Don’t make us go to Canada!)`;

interface PersonalPropertyViewModel {
  deductible: number;
  isLoading: boolean;
  onDeductibleIncrease: () => Promise<void>;
  onDeductibleDecrease: () => Promise<void>;
  onPersonalPropertyToggle: () => Promise<void>;
  hasPersonalProperty: boolean;
  maximumAmountReimbursed: number;
  onMaximumAmountReimbursedIncrease: () => Promise<void>;
  onMaximumAmountReimbursedDecrease: () => Promise<void>;
}

const PropertyIcon = styled.Image`
  width: 50px;
  height: 50px;
`;

const PropertyIconContainer = styled.View`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PropertyIconContainerRow = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 348px;
`;

const PropertyTextContainer = styled.View`
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  flex: 1;
  overflow-wrap: break-word;
`;

export default function PersonalPropertyExperiment(
  personalProperty: PersonalPropertyViewModel
): React.ReactElement {
  return (
    <CoverageCard bgColor="white">
      <VerticalCenter>
        <PersonalPropertyIcons />
        <Spacer x={2.5} y={2.5} />
        <CoverageCardTitle>{title}</CoverageCardTitle>

        <Spacer y={1.5} />

        <CoverageCardDescription>{description}</CoverageCardDescription>

        <Spacer x={2} y={4} />

        <PropertyIconContainer>
          <PropertyIconContainerRow>
            <PropertyIcon source={rentalImages.theftIconSource} />
            <Spacer x={0.5} />
            <PropertyTextContainer>
              <WaffleText style={{ fontSize: 14 }}>
                {'Theft + Replacement Coverage'}
              </WaffleText>
              <Spacer y={0.5} />
              <WaffleText style={{ fontSize: 10, color: '#88847E' }}>
                {
                  "Damaged, lost, or stolen at home or out and about? We'll buy you a new one or one of equal value!*"
                }
              </WaffleText>
            </PropertyTextContainer>
          </PropertyIconContainerRow>
          <Spacer y={2} />
          <PropertyIconContainerRow>
            <PropertyIcon source={rentalImages.badWeatherIconSource} />
            <Spacer x={0.5} />
            <PropertyTextContainer>
              <WaffleText style={{ fontSize: 14 }}>
                {'Elemental Coverage'}
              </WaffleText>
              <Spacer y={0.5} />
              <WaffleText style={{ fontSize: 10, color: '#88847E' }}>
                {
                  'Fire damage, water damage, hail, windstorm, or even just bad weather!'
                }
              </WaffleText>
            </PropertyTextContainer>
          </PropertyIconContainerRow>
        </PropertyIconContainer>

        <Spacer x={4} y={4} />

        <Toggle
          isDisabled={personalProperty.isLoading}
          onPress={personalProperty.onPersonalPropertyToggle}
          isEnabled={personalProperty.hasPersonalProperty}
        />

        {!personalProperty.hasPersonalProperty ? null : (
          <>
            <Spacer x={4} y={4} />
            <CoverageCardCounter
              label="Maximum Amount Reimbursed"
              value={personalProperty.maximumAmountReimbursed}
              onIncrease={personalProperty.onMaximumAmountReimbursedIncrease}
              onDecrease={personalProperty.onMaximumAmountReimbursedDecrease}
            />

            <Spacer x={4} y={4} />

            <CoverageCardCounter
              label="Deductible"
              value={personalProperty.deductible}
              onIncrease={personalProperty.onDeductibleIncrease}
              onDecrease={personalProperty.onDeductibleDecrease}
              description="Note: All wind/hail events have a $1,000 deductible except in certain
        states."
            />

            <Spacer x={2} y={4} />
            <WaffleText
              style={{
                textAlign: 'center',
                fontSize: 12,
                color: '#88847E',
              }}>
              {
                '*Coverage is subject to the terms and conditions of the policy, please read the full policy '
              }
              <a
                href="https://f.hubspotusercontent40.net/hubfs/8720248/Sample%20policies/Renters%20Insurance%20Sample%20Policy.pdf"
                target="_blank"
                style={{
                  color: WaffleOrange,
                  textDecorationLine: 'underline',
                }}
                rel="noreferrer">
                here
              </a>
              {' for details. '}
            </WaffleText>
          </>
        )}
      </VerticalCenter>
    </CoverageCard>
  );
}
