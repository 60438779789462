import React from 'react';
import PersonalPropertyExperiment from './PersonalProperty.experiment';
import PersonalProperty from './PersonalProperty';

interface Props {
  deductible: number;
  isLoading: boolean;
  isExperiment?: boolean;
  onDeductibleIncrease: () => Promise<void>;
  onDeductibleDecrease: () => Promise<void>;
  onPersonalPropertyToggle: () => Promise<void>;
  hasPersonalProperty: boolean;
  maximumAmountReimbursed: number;
  onMaximumAmountReimbursedIncrease: () => Promise<void>;
  onMaximumAmountReimbursedDecrease: () => Promise<void>;
}

export default function PersonalPropertyWrapper({
  isExperiment = false,
  ...props
}: Props): React.ReactElement {
  if (isExperiment) {
    return <PersonalPropertyExperiment {...props} />;
  }

  return <PersonalProperty {...props} />;
}
