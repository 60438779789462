import React from 'react';
import WaffleText from '../../Components/WaffleText';
import styled from 'styled-components/native';
import rentalImages from './utils/rental-images';

const hasAllIconSources =
  rentalImages.bikeIconSource &&
  rentalImages.cameraIconSource &&
  rentalImages.diamondRingIconSource &&
  rentalImages.guitarIconSource &&
  rentalImages.laptopIconSource &&
  rentalImages.smartphoneIconSource;

const Container = styled.View`
  flex-direction: row;
  justify-content: center;
`;

const PropertyIcon = styled.Image`
  width: 50px;
  height: 50px;
`;

export default function PersonalPropertyIcons() {
  if (!hasAllIconSources) return <WaffleText>img</WaffleText>;

  return (
    <Container>
      <PropertyIcon source={rentalImages.laptopIconSource} />
      <PropertyIcon source={rentalImages.bikeIconSource} />
      <PropertyIcon source={rentalImages.guitarIconSource} />
      <PropertyIcon source={rentalImages.cameraIconSource} />
      <PropertyIcon source={rentalImages.smartphoneIconSource} />
    </Container>
  );
}
